import React, { useEffect } from 'react';
import './Banner.css';

interface BannerProps {
  title: string;
  subtitle?: string;
  bannerId: number;
  link?: {
    title: string;
    url: string;
  };
}

const Banner: React.FC = () => {
  const [banners, setBanners] = React.useState<BannerProps[]>([]);
  const [visibleIndex, setVisibleIndex] = React.useState(0);

  useEffect(() => {
    //Use fetch, and call our API to see if we have any banner info to display
    const bannerEndpoint = `${process.env.REACT_APP_API_SERVER}/banners`;
    //include my auth heaaders in this fetch call
    fetch(bannerEndpoint, {
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((data) => {
        //Set title and subtitle based on the response
        setBanners(data);
      })
      .catch((err) => {
        console.log('ERROR:' + err);
      });
  }, []);

  const dismissBanner = (index: number) => {
    setBanners((prevBanners) => prevBanners.filter((_, i) => i !== index));
  };

  if (banners.length === 0) return null;
  return (
    <div className='banner'>
      {banners.map((banner, index) => (
        <div key={index} hidden={visibleIndex !== index}>
          <h1>{banner.title}</h1>
          {banner.subtitle && <h2>{banner.subtitle}</h2>}
          <a href={banner.link?.url}>{banner.link?.title}</a>
          <button
            className='dismiss-button'
            onClick={() => dismissBanner(index)}
          >
            X
          </button>
        </div>
      ))}
    </div>
  );
};

export default Banner;
